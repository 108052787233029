import Control from '@/js/Controls/Control'
import { tns } from 'tiny-slider/src/tiny-slider'
import { TinySliderInstance, TinySliderSettings } from 'tiny-slider'
import React from 'jsx-dom'
import { deepMerge } from 'collapsable.js'

interface HTMLCarouselElement extends HTMLElement {
	carousel: TinySliderInstance
}

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			[elemName: string]: any
		}
	}
}

class CarouselControl implements Control {
	public initialize(context: Element | Document): void {
		const carousels = context.querySelectorAll<HTMLCarouselElement>('.js-carousel')

		carousels.forEach((carouselWrapper) => {
			const container = carouselWrapper.querySelector<HTMLElement>('.js-carousel__carousel')
			if (!container) return

			const itemsCount = container.querySelectorAll('.js-carousel__item').length
			if (itemsCount <= 1) return

			const dataOptions = JSON.parse(carouselWrapper.dataset.carousel ?? '{}')
			const controlsContainer = this.buildControlsContainer(carouselWrapper)
			const navContainer = this.buildNavContainer(carouselWrapper, itemsCount)
			const autoplayButton = '.js-carousel__pause'

			const defaultOptions: TinySliderSettings = {
				container,
				controlsContainer,
				navContainer,
				autoplayButton,
				autoplayText: [
					'<span class="iconify-[mdi--play]"></span>',
					'<span class="iconify-[mdi--pause]"></span>'
				],
				loop: true
			}

			const options = deepMerge({}, defaultOptions, dataOptions)

			carouselWrapper.carousel = tns(options)

			if (carouselWrapper.classList.contains('js-carousel--names')) {
				carouselWrapper.carousel.events.on('indexChanged', () =>
					this.checkVisibleName(
						navContainer,
						navContainer.parentElement,
						carouselWrapper.carousel.getInfo().index
					)
				)
			}
		})
	}

	private checkVisibleName(nav: HTMLElement, parent: HTMLElement | null, index: number) {
		const wrapWidth = nav.offsetWidth
		const page = nav.children.item(index - 1) as HTMLElement

		if (page && parent) {
			const pageWidth = page.offsetWidth
			const pageLeft = page.offsetLeft

			if (pageLeft + pageWidth < parent.scrollLeft) {
				parent?.scrollTo({
					top: 0,
					left: pageLeft,
					behavior: 'smooth'
				})
			}

			if (pageWidth + pageLeft > wrapWidth + parent.scrollLeft) {
				parent?.scrollTo({
					top: 0,
					left: pageWidth + pageLeft - wrapWidth,
					behavior: 'smooth'
				})
			}
		}
	}

	private buildControlsContainer(carouselWrapper: HTMLElement): HTMLElement {
		const controls = carouselWrapper.querySelector<HTMLElement>('.js-carousel__controls')

		if (controls) {
			return controls
		}

		const customControls: Element = (
			<p class="js-carousel__controls">
				<button type="button" class="js-carousel__control js-carousel__control--prev">
					<span class="sr-only">{carouselWrapper.dataset.prev}</span>
				</button>
				<button type="button" class="js-carousel__control js-carousel__control--next">
					<span class="sr-only">{carouselWrapper.dataset.next}</span>
				</button>
			</p>
		)

		carouselWrapper.insertAdjacentElement('afterbegin', customControls)

		return customControls as HTMLElement
	}

	private buildNavContainer(carouselWrapper: HTMLElement, itemsCount: number): HTMLElement {
		const nav = carouselWrapper.querySelector<HTMLElement>('.js-carousel__nav')

		if (nav) {
			return nav
		}

		const customNav: HTMLElement = (<p class="js-carousel__paging"></p>) as HTMLElement

		for (let i = 0; i < itemsCount; i++) {
			customNav.appendChild(<button type="button" class="js-carousel__page" style="display: none"></button>)
		}

		carouselWrapper.insertAdjacentElement('beforeend', customNav)

		return customNav
	}
}

export default new CarouselControl()
